import ReCAPTCHA from 'react-google-recaptcha';
import { styled } from 'Theme/stitches.config';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import Button from 'Shared/Components/Buttons/Button';
import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
//import InputReactForm from 'DesignComponents/Atoms/Input/InputReactForm';
import Label from 'DesignSystem/Typography/Label/Label';
import { API_ROOT_PATH } from 'Shared/Constants/route';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import Checkbox from 'DesignComponents/Atoms/Input/Checkbox';
import TermsAndConditions from 'Commerce/Pages/Checkout/Components/TermsAndConditions/TermsAndConditions';
import { useRef, useState } from 'react';
import TermsAndConditionsModel from 'Models/TermsAndConditions/TermsAndConditionsModel.interface';
import ContactInfo from './ContactInfo';
import { getBookClubFormSchema } from './bookClubFormSchema';
import FormSubmitted from 'DesignComponents/Molecules/FormSubmitted/FormSubmitted';
import { debounce } from 'Shared/Common/debounce';
import DeliveryInfo from './DeliveryInfo';
import { GetShippingDestination } from 'Commerce/CountryPicker/ShippingDestination';

type BookClubType = {
  reCaptchaKey: string;
  termsAndConditions: TermsAndConditionsModel;
  bookClubName: string;
};

interface Address {
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  door: string;
  floor: string;
  letter: string;
  fullAddress: string;
  street: string;
  streetNumber: string;
}

interface ShippingAndBillingAddresses {
  shippingAddress: Address;
  billingAddress: Address;
}

export const POST_BOOKCLUB_FORM_URL = API_ROOT_PATH + '/bookclub/register';

function BookClubForm({
  reCaptchaKey,
  termsAndConditions,
  bookClubName,
}: BookClubType) {
  const currentShippingDestination = GetShippingDestination();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);
  const [submissionResult, setSubmissionResult] = useState<string | null>(null);
  const [captchaValue, setCaptchaValue] = useState<string>('');
  const [showCaptchaValidation, setShowCaptchaValidation] = useState(false);
  const [
    showTermsAndConditionsValidation,
    setShowTermsAndConditionsValidation,
  ] = useState(false);

  const {
    errorLabels: { errorFetchingData },
    checkoutLabels: { terms, iAccept, orderForm },
    commonLabels: {
      register: registerLabel,
      submittedBookClubFormHeader,
      submittedBookClubFormBody,
    },
    validationLabels: { confirmNoRobot, confirmTermsAndConditions },
    validationLabels,
  } = useTranslationData();

  const formRef = useRef<HTMLFormElement>(null);

  const bookClubFormConfig = getBookClubFormSchema(validationLabels);

  type bookClubFormType = z.infer<typeof bookClubFormConfig>;

  const {
    register,
    handleSubmit,
    formState,
    getValues,
    reset,
    setValue,
    trigger,
    clearErrors,
  } = useForm<bookClubFormType>({
    resolver: zodResolver(bookClubFormConfig),
    mode: 'onSubmit',
  });

  const handleCaptchaChange = (value: string) => {
    setCaptchaValue(value);
  };

  const submit = async () => {
    if (!termsAndConditionsAccepted) {
      setShowTermsAndConditionsValidation(true);
    }

    if (!captchaValue) {
      setShowCaptchaValidation(true);
      return;
    }

    const formValues = {
      ...getValues(),
      token: captchaValue,
    };

    const request = {
      token: captchaValue,
      bookClubName: bookClubName,
      email: formValues.email,
      phoneNumber: formValues.phoneNumber,
      ...MapToAddress(formValues, billingSameAsShipping),
    };

    setSubmitting(true);

    fetch(POST_BOOKCLUB_FORM_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        reset();
        setTermsAndConditionsAccepted(false);
        setBillingSameAsShipping(true);
        setSubmitted(true);
      })
      .catch(() => {
        setSubmissionResult(errorFetchingData);
      })
      .finally(() => {
        setSubmitting(false);
        setShowCaptchaValidation(false);
        setShowTermsAndConditionsValidation(false);
      });
  };

  type bookClubRegistrationKeys = keyof z.infer<typeof bookClubFormConfig>;

  const createAddress = (
    data: Record<bookClubRegistrationKeys, string>,
    type: 'shipping' | 'billing'
  ) => {
    const firstName = data[`${type}_firstName`];
    const lastName = data[`${type}_lastName`];
    const address = data[`${type}_address`];
    const postalCode = data[`${type}_postalCode`];
    const city = data[`${type}_city`];

    return {
      firstName,
      lastName,
      address,
      postalCode,
      city,
      country: currentShippingDestination?.code || '',
      door: '',
      floor: '',
      letter: '',
      fullAddress: `${address}, ${postalCode} ${city}`,
      street: address,
      streetNumber: '',
    };
  };

  const MapToAddress = (
    data: Record<bookClubRegistrationKeys, string>,
    sameAsShipping = true
  ): ShippingAndBillingAddresses => {
    const shippingAddress = createAddress(data, 'shipping');
    const billingAddress = sameAsShipping
      ? shippingAddress
      : createAddress(data, 'billing');

    return {
      shippingAddress: shippingAddress,
      billingAddress: billingAddress,
    };
  };

  const TermsAndConditionsDiv = () => {
    return (
      <>
        {iAccept + ' '}&nbsp;{' '}
        <TermsAndConditions
          termsAndConditions={termsAndConditions}
          type="bookClub"
        />
        .
      </>
    );
  };

  const UpdateEntireForm = () => {
    //when fields are autofilled, react-hook-form does not update the form state
    //so we need to manually update the form state to reflect the current values in the form

    const form = formRef.current;
    if (form) {
      const formElements = form.elements;
      for (let i = 0; i < formElements.length; i++) {
        const element = formElements[i] as HTMLInputElement;
        if (element.name) {
          setValue(element.name as keyof bookClubFormType, element.value);

          if (billingSameAsShipping && element.name.startsWith('shipping_')) {
            setValue(
              element.name.replace(
                'shipping_',
                'billing_'
              ) as keyof bookClubFormType,
              element.value
            );
          } else if (
            !billingSameAsShipping &&
            element.name.startsWith('billing_')
          ) {
            setValue(element.name as keyof bookClubFormType, element.value);
          }

          const value = getValues(element.name as keyof bookClubFormType);
          if (value) trigger(element.name as keyof bookClubFormType);
          element.value = value;
        }
      }
    }
  };

  const SetValue = (fieldName: keyof bookClubFormType, value: string) => {
    debounce(() => {
      setValue(fieldName, value);
      UpdateEntireForm();
    }, 500);
  };

  const handleSetBillingsSameAsShipping = (checked: boolean) => {
    //if billing is same as shipping, copy shipping values to billing fields
    //this is done to avoid validation errors when submitting the form
    //if billing is not same as shipping, clear billing fields
    //and clear any validation errors

    setBillingSameAsShipping(!billingSameAsShipping);
    if (!checked) {
      setValue('billing_firstName', '');
      clearErrors('billing_firstName');
      setValue('billing_lastName', '');
      clearErrors('billing_lastName');
      setValue('billing_address', '');
      clearErrors('billing_address');
      setValue('billing_postalCode', '');
      clearErrors('billing_postalCode');
      setValue('billing_city', '');
      clearErrors('billing_city');
    } else {
      setValue('billing_firstName', getValues('shipping_firstName'));
      setValue('billing_lastName', getValues('shipping_lastName'));
      setValue('billing_address', getValues('shipping_address'));
      setValue('billing_postalCode', getValues('shipping_postalCode'));
      setValue('billing_city', getValues('shipping_city'));
    }
  };

  return (
    <Root>
      <Form ref={formRef}>
        <Header>
          <Heading tag="h2" size="m">
            {orderForm || 'Beställningsformulär'}
          </Heading>
        </Header>
        <ContactInfo
          register={register}
          formState={formState}
          setValue={SetValue}
        />
        <DeliveryInfo
          register={register}
          formState={formState}
          setValue={SetValue}
          billingSameAsShipping={billingSameAsShipping}
          handleSetBillingsSameAsShipping={handleSetBillingsSameAsShipping}
        />
        <Terms>
          <Heading tag="h3" size="s">
            {terms}
          </Heading>

          <div>
            <Checkbox
              button
              isChecked={termsAndConditionsAccepted}
              onCheck={() =>
                setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
              }
              children={TermsAndConditionsDiv}
            />
            {showTermsAndConditionsValidation && (
              <Label error={true} role="alert">
                {confirmTermsAndConditions}
              </Label>
            )}
          </div>
          <div>
            <ReCAPTCHA
              sitekey={reCaptchaKey}
              onChange={handleCaptchaChange}
              size="normal"
            />
            {showCaptchaValidation && (
              <Label error={true} role="alert">
                {confirmNoRobot}
              </Label>
            )}
          </div>
        </Terms>

        <div>
          <Button<typeof PrimaryButton>
            element={'PrimaryButton'}
            props={{
              text: registerLabel,
              color: 'Regular',
              hug: 'width',
            }}
            isLoading={submitting}
            onClick={handleSubmit(submit)}
          />
        </div>
        {submissionResult && (
          <SubmissionResult>{submissionResult}</SubmissionResult>
        )}
      </Form>

      {submitted && (
        <FormSubmitted
          title={submittedBookClubFormHeader}
          message={submittedBookClubFormBody}
        />
      )}
    </Root>
  );
}

const border = {
  border: '1px solid $onSurfaceDisabled',
  borderRadius: '4px',
};

const maxWidth = {
  maxWidth: '640px',
};

const Root = styled('div', {
  ...maxWidth,
  mx: 'auto',
  my: '$s200',
});

const Form = styled('form', {
  mb: 4,
  '& iframe': {
    ...border,
  },

  '& div:has(button)': {
    marginTop: 20,
  },
  '@bpMax720': {
    mx: 4,
  },
});

const Header = styled('div', {
  maxWidth: '640px',
  margin: '32px 16px',
  '@media (min-width: 721px)': {
    margin: '32px auto',
  },
});

const Terms = styled('fieldset', {
  display: 'flex',
  flexDirection: 'column',
  px: 0,
  pb: 0,
  g: '16px',
  ' & div > div:has(input[type="checkbox"])': {
    ...border,
    p: 4,
  },
});

const SubmissionResult = styled('div', {
  my: '$s200',
  p: 4,
  color: '#a40820',
  border: '1px solid #a40820',
  borderRadius: '4px',
  backgroundColor: '#f8e7ea',
});

export default BookClubForm;
