import ContentArea from 'DesignComponents/Organisms/ContentArea/ContentArea';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import HeroModule from 'DesignComponents/Organisms/Hero/HeroModule';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import BookClubForm from 'DesignComponents/Organisms/BookClubForm/BookClubForm';
import BookClubPageModel from 'Models/Pages/BookClubPage/BookClubPageModel.interface';

function BookClubPage() {
  const {
    hero,
    contentArea,
    inEditMode,
    reCaptchaKey,
    bookClubName,
    termsAndCondition,
  } = useCurrentPage<BookClubPageModel>();

  return (
    <>
      {hero && <HeroModule hero={hero} />}

      <ContentContainer {...applyEditModeAttr(inEditMode && 'MainContent')}>
        {Array.isArray(contentArea) && <ContentArea childItems={contentArea} />}
      </ContentContainer>

      <BookClubForm
        reCaptchaKey={reCaptchaKey}
        termsAndConditions={termsAndCondition}
        bookClubName={bookClubName}
      />
    </>
  );
}

export default BookClubPage;
